import React from "react";
import "./new.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ParticlesBg from "particles-bg";

import Web3 from "web3";
import "@metamask/legacy-web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ABITOKEN from "../Abi/token.json";
import ABICONTRACT from "../Abi/contractAW.json";
import pdf from "../MDF.pptx.pdf";

function Home() {
  var id = window.top.location.hash.substring(1),k = 0,
  mynetworkid = 1280,

   contractAddress = "0x7129357B9D66355c228dA5e32844e12D8f9B1B59",
   tokenAddress = "0x0c4cc6eaa9fc4aad02f939766ea9ce4a77d2b5cc",
   [plaAmo, setplaAmo] = React.useState(1000),
   [refAddr, setrefAddr] = React.useState(id == "" ? "0x7C9491b23705c7745aa4732cd4Ff2F62E1c36846" : id),
   [loadState, setLoading] = React.useState(false),
   [loadStatewith, setLoadingwith] = React.useState(false),
   [totalUsers, settotalUsers] = React.useState(0),
   [totalInvested, settotalInvested] = React.useState(0),
   [collection, setcollection] = React.useState(0),
   [userAddress, setUserAddress] = React.useState(0),
   [singleFamilyUp, setsingleFamilyUp] = React.useState(0),
   [singleFamilyDown, setsingleFamilyDown] = React.useState(0),
   [bonusLevelIncome, setbonusLevelIncome] = React.useState(0),
   [walletBalance, setwalletBalance] = React.useState(0),
   [user, setUser] = React.useState([]),
   [familyArray, setfamilyArray] = React.useState([]),
   [withdraw, setwithdraw] = React.useState(0),
   [reinvest, setreinvest] = React.useState(0),
   [level1, setlevel1] = React.useState(0),
   [level2, setlevel2] = React.useState(0),
   [level3, setlevel3] = React.useState(0),
   [level4, setlevel4] = React.useState(0),
   [Pool, setpool] = React.useState(0),
   [PoolBal, setpoolBal] = React.useState(0),
   [withdrawableAmount, setwithdrawableAmount] = React.useState(0),
   [url] = React.useState("https://mutualdigitalfunding.tech/#");

  async function withdrawAmount() {
    setLoadingwith(true);
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            if (window.web3.currentProvider.networkVersion == mynetworkid) {
              if (window.web3.currentProvider.isMetaMask == true) {
                await web3.eth.getAccounts(async function (error, result) {
                  var ContractMLM = new web3.eth.Contract(
                    ABICONTRACT,
                    contractAddress
                  );
                  if (parseFloat((withdrawableAmount * withdraw) / 100) < 1) {
                    setLoadingwith(false);
                    toast.error("Your Balance is too low");
                    return false;
                  }

                  await ContractMLM.methods
                    .withdrawal((withdrawableAmount < user.alowablewithdrawal / 1e18)?
                    Math.round(withdrawableAmount *0.95)
                  : Math.round(user.alowablewithdrawal / 1e18))
                    .send({
                      from: result[0],
                    })
                    .then(async (result) => {
                      setLoadingwith(false);
                      toast.success("Successfully withdrawed");
                      window.location.reload(true);
                    })
                    .catch((error) => {
                      setLoadingwith(false);
                      toast.error("Please Check your gas fees");
                    });
                });
              }
            } else {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x500',
                  chainName: 'Halo Network',
                  nativeCurrency: {
                      name: 'HO',
                      symbol: 'HO',
                      decimals: 18
                  },
                  rpcUrls: ['https://nodes.halo.land'],
                  blockExplorerUrls: ['https://browser.halo.land/']
                  }]
                })
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x500' }],
                });
            }
          });
        } else {
          setLoadingwith(false);
          toast.warning("Please Add Metamask External");
        }
      } catch (err) {
        setLoadingwith(false);
      }
    } else {
      setLoadingwith(false);
      toast.warning("Please Add Metamask External");
    }
  }

  async function walletconnect() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
        if (typeof web3 !== "undefined") {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            if (window.web3.currentProvider.networkVersion == mynetworkid) {
              if (window.web3.currentProvider.isMetaMask == true) {
                await web3.eth.getAccounts(async function (error, result) {
                  setUserAddress(result[0]);

                  if (refAddr == result[0]) setrefAddr("0x7C9491b23705c7745aa4732cd4Ff2F62E1c36846");
                  var ContractMLM = new web3.eth.Contract(ABICONTRACT,contractAddress),
                    ContractTok = new web3.eth.Contract(ABITOKEN,tokenAddress);
                  setwalletBalance(await ContractTok.methods.balanceOf(result[0]).call() / 1e18);
                  settotalUsers(await ContractMLM.methods.totalUsers().call());
                  settotalInvested(await ContractMLM.methods.totalInvested().call() / 1e18);
                  setcollection(await ContractMLM.methods.totalWithdrawn().call() / 1e18);
                  
                  var upIncome = await ContractMLM.methods.GetUplineIncomeByUserId(result[0]).call();
                  setsingleFamilyUp(upIncome / 1e18);
                  
                  var downIncome = await ContractMLM.methods.GetDownlineIncomeByUserId(result[0]).call();
                  setsingleFamilyDown(downIncome / 1e18);
                  
                  var TotalBonus = await ContractMLM.methods.TotalBonus(result[0]).call();
                  setbonusLevelIncome(TotalBonus / 1e18);
                  var userDet = await ContractMLM.methods.users(result[0]).call();
                  
                  userDet.Income = upIncome + downIncome;
                  
                  setUser(userDet);
                  setwithdrawableAmount(TotalBonus / 1e18);
                  
                  var withdrawPercent = await ContractMLM.methods.getEligibleWithdrawal(result[0]).call();
                  setwithdraw(withdrawPercent.withdrwal);
                  setreinvest(withdrawPercent.reivest);
                  setlevel1(await ContractMLM.methods.referral_stage(result[0], 0).call());
                  setlevel2(await ContractMLM.methods.referral_stage(result[0], 1).call());
                  setpool(await ContractMLM.methods.poolAmount().call() / 1e18);
                  setpoolBal(await ContractMLM.methods.poolAmount().call() / 1e18);
                  setlevel3(await ContractMLM.methods.referral_stage(result[0], 2).call());
                  setlevel4(await ContractMLM.methods.referral_stage(result[0], 3).call());
                  var familyArray = [], currentUser1 = userDet.singleUpline, cuurentUserArray = userDet;
                  cuurentUserArray.id = result[0];

                  cuurentUserArray.Income =
                    parseInt(await ContractMLM.methods.GetUplineIncomeByUserId(result[0]).call()) + 
                      parseInt(await ContractMLM.methods.GetDownlineIncomeByUserId(result[0]).call());
                  var blank="0x0000000000000000000000000000000000000000";
                  for (var i = 0; i < 20; i++) {
                    if (currentUser1 == blank)break;
                    userDet = await ContractMLM.methods.users(currentUser1).call();
                    userDet.id = currentUser1;
                    if (!familyArray.some((el) => el.id === currentUser1)) familyArray.push(userDet);
                    userDet.Income =
                      parseInt(await ContractMLM.methods.GetUplineIncomeByUserId(currentUser1).call()) + 
                      parseInt(await ContractMLM.methods.GetDownlineIncomeByUserId(currentUser1).call());
                    currentUser1 = userDet.singleUpline;
                  }
                  if (!familyArray.some((el) => el.id === result[0])) familyArray.push(cuurentUserArray);
                  var currentUser = userDet.singleDownline;
                  for (i = 0; i < 50; i++) {
                    if (currentUser == blank)break;
                    var userDet = await ContractMLM.methods.users(currentUser).call();
                    userDet.id = currentUser;
                    if (!familyArray.some((el) => el.id === currentUser)) familyArray.push(userDet);
                    userDet.Income = 
                      parseInt(await ContractMLM.methods.GetUplineIncomeByUserId(currentUser).call()) +
                      parseInt(await ContractMLM.methods.GetDownlineIncomeByUserId(currentUser).call());
                    currentUser = userDet.singleDownline;
                  }
                  setfamilyArray(familyArray.filter((value) => value.referrer != blank))});
              }
            } else {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: '0x500',
                chainName: 'Halo Network',
                nativeCurrency: {
                    name: 'HO',
                    symbol: 'HO',
                    decimals: 18
                },
                rpcUrls: ['https://nodes.halo.land'],
                blockExplorerUrls: ['https://browser.halo.land/']
                }]
              })
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x500' }],
            });}
          });
        } else toast.warning("Please Add Metamask External");
    } else toast.warning("Please Add Metamask External");
  }

  window.addEventListener("load", (event) => {
    walletconnect();
    event.preventDefault();
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        window.location.reload(true);
      });

      window.ethereum.on("networkChanged", function (networkId) {
        if (networkId == mynetworkid) 
          window.location.reload(true);
      });
    }
  });

  async function buyAmount(amount) {
    setplaAmo(amount);
  }

  async function refAddress(e) {
    setrefAddr(e.target.value);
  }

  async function register(e) {
    setLoading(true);
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            if (window.web3.currentProvider.networkVersion == mynetworkid) {
              if (window.web3.currentProvider.isMetaMask == true) {
                await web3.eth.getAccounts(async function (error, result) {
                  var ContractTok = new web3.eth.Contract(
                    ABITOKEN,
                    tokenAddress
                  );

                  var ContractMLM = new web3.eth.Contract(
                    ABICONTRACT,
                    contractAddress
                  );

                  var balanceUser = await ContractTok.methods
                    .balanceOf(result[0])
                    .call()

                  if (balanceUser / 1e18 < parseInt(plaAmo)) {
                    setLoading(false);
                    toast.error("Your Balance is too low");
                    return false;
                  }

                  if (refAddr == "") {
                    setLoading(false);
                    toast.error("Please provice referral address");
                    return false;
                  }
                  var approve = await ContractTok.methods
                    .approve(
                      contractAddress,
                      web3.utils.toWei(plaAmo.toString(), "ether")
                    )
                    .send({
                      from: result[0],
                    })
                    .then(async (resultApprove) => {
                      toast.success("Approved SuccessFully");
                    })
                    .catch((error) => {
                      toast.error("Error while Approve Token");
                    });

                  await ContractMLM.methods
                    .invest(
                      refAddr,
                      web3.utils.toWei(plaAmo.toString(), "ether")
                    )
                    .send({
                      from: result[0],
                    })
                    .then(async (resultSucc) => {
                      setLoading(false);
                      window.location.reload(true);
                      toast.success("Successfully created");
                    })
                    .catch((error) => {
                      setLoading(false);
                      toast.error("Please Check your Referral or Gas Fees");
                    });
                });
              }
            } else {
              setLoading(false);
              toast.warning("Please Connect to Halo Network");
            }
          });
        } else {
          setLoading(false);
          toast.warning("Please Add Metamask External");
        }
      } catch (err) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.warning("Please Add Metamask External");
    }
  }

  function reload(){
    window.location.reload(true);
  }

  function changeText(){
    const ArrChinese=["100% DAO (去中心化的自治组织)","买AW币","按这","贡献者","贡献","代币提现","共享池金额",
    "你的推荐人","注册共同数字基金","推荐人钱包地址","注册","单脚线","上","单脚线","下","全部","收录","钱包","育儿",
    "你的贡献","提现","可以提款金额","钱包金额","以提现的AW币 (以建 5% 手续费)","福特金额 (AW)","提现金额 (AW)",
    "福特","提现","提现","全家线","代","投资金额","收入","家人代","代","数","投资金额","收入","(要求 4 赞助商)",
    "(要求 6 赞助商)","您的推荐链接","复制链接","下载","下载"];
    for(var i=1;i<ArrChinese.length+1;i++)document.getElementById((i<10?'tt_00':'tt_0')+i).innerHTML=ArrChinese[i-1];
  }

  const onawclick= () => window.open('https://actionworld.io/index.html#/swap');

  return (
    <div className="body_bg loaded">
      <ToastContainer />
      <ParticlesBg color="#ffb73b" num={50} type="cobweb" bg={true} />

      <div className="batman">
        <div className="container">
          <div>
            <div className="banner_inner">
              {/* <img className="banner_logo" src={require('../assests/big_logo.png')}  alt=""/> */}
              <h1 className="mdf">Mutual Digital Funding</h1>
              {/* <div className="text-center">
                <a target="_blank" className="popup-youtube" href="#">
                  <img
                    className="video-icon"
                    src={require("../assests/video-icon.png")}
                    alt=""
                  />
                </a>
              </div> */}
              <div style={{position: 'absolute', right:'15px',color : 'white', cursor:'pointer'}}>
                <a onClick={reload}>EN</a> | <a onClick={changeText}>CN</a></div>
              <p className="verify"id="tt_001">
                100% DAO (Decentralized Autonomous Organizations)
              </p>
            </div>
          </div>
          <div className="pb_30">
            <div>
              <div className="row cus_row">
              <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner buyaw" onClick={onawclick}>
                    <img src={require("../assests/icon4.png")} alt="" />
                    <h4 id="tt_002">Buy AW Token</h4>
                    <b id="tt_003">Click here</b>
                  </div>
                  </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon4.png")} alt="" />
                    <h4 id="tt_004">Contributors</h4>
                    <h5>{totalUsers}</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon5.png")} alt="" />
                    <h4 id="tt_005">Contribution</h4>
                    <h5>{Math.round(totalInvested)} AW</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon6.png")} alt="" />
                    <h4 id="tt_006">Token Withdrawn</h4>
                    <h5>{Math.round(collection)} AW</h5>
                  </div>
                </div>
                {/* <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon6.png")} alt="" />
                    <h4>Remaining Withdraw</h4>
                    <h5>{totalInvested - collection} AW</h5>
                  </div>
                </div> */}
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon6.png")} alt="" />
                    <h4 id="tt_007">Pool Amount</h4>
                    <h5>{Math.round(totalInvested * 0.02)} AW</h5>
                  </div>
                </div>

                <div className="col-md-4 col-6">
                <div className="Personal_Details_inner">
                    <img src={require("../assests/icon3.png")} alt="" />
                    <h4 id="tt_008">Your sponsor</h4>
                    <h5>{user && user.referrer}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb_30">
            <div>
              <div className="sm_container">
                <div className="sm_container_bg">
                  <div className="sm_container_padding">
                    <div className="all_heading text-center">
                      <h3 id="tt_009">Register to Mutual Digital Funding</h3>
                    </div>
                    <div className="form-group mb_20">
                      <label className="white_label" id="tt_010">Sponsor wallet address</label>
                      <input
                        className="cus_input"
                        type="text"
                        placeholder="Sponsor wallet addresss"
                        onChange={refAddress}
                        value={refAddr}
              ></input>*/
                    </div>
                    <ul className="trx_btn">
                        {/*<li>
                        <button
                          className="inactive"
                          onClick={() => buyAmount(100)}
                        >
                         100 AW
                        </button>
                      </li>
                      <li>
                        <button
                          className="inactive"
                          onClick={() => buyAmount(500)}
                        >
                          500 AW
                        </button>
                        </li> */}
                      <li>
                        <button
                          className="inactive"
                          onClick={() => buyAmount(1000)}
                        >
                          1,000 AW
                        </button>
                      </li>
                      <li>
                        <button
                          className="inactive"
                          onClick={() => buyAmount(3000)}
                        >
                          3,000 AW
                        </button>
                      </li>
                      <li>
                        <button
                          className="inactive"
                          onClick={() => buyAmount(5000)}
                        >
                          5,000 AW
                        </button>
                      </li>
                      <li>
                        <button
                          className="inactive"
                          onClick={() => buyAmount(10000)}
                        >
                          10,000 AW
                        </button>
                      </li>
                      <li>
                        <button
                          className="inactive"
                          onClick={() => buyAmount(30000)}
                        >
                          30,000 AW
                        </button>
                      </li>
                    </ul>
                    <div className="text-center mt_20">
                      <button
                        className="grad_btn btn_300"
                        onClick={register}
                        disabled={loadState}
                        id="tt_011">
                        {loadState && (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb_60">
            <div>
              <div className="small_heading">
                <h5>ID {userAddress}</h5>
              </div>
              <div className="row cus_row">
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4 id="tt_012">Single leg family</h4>
                    <p id="tt_013">Up</p>
                    <h5>{Math.round(singleFamilyUp)} AW</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4 id="tt_014">Single leg family</h4>
                    <p id="tt_015">Down</p>
                    <h5>{Math.round(singleFamilyDown)} AW</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4 id="tt_016">Balance in </h4>
                    <p id="tt_017">Account</p>
                    <h5>{Math.round(bonusLevelIncome)} AW</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4 id="tt_018">Your wallet </h4>
                    <p id="tt_019">balance</p>
                    <h5>{Math.round(walletBalance)} AW</h5>
                  </div>
                </div>
              </div>
              <div className="row cus_row">
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon1.png")} alt="" />
                    <h4 id="tt_020">Your contribution</h4>
                    <h5>{Math.round(user && user.amount / 1e18)} AW</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon2.png")} alt="" />
                    <h4 id="tt_021">Withdraw Amount</h4>
                    <h5>{Math.round(user && user.totalWithdrawn / 1e18)} AW</h5>
                  </div>
                </div>

                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                  <br></br><p id="tt_022">Remaining Withdraw Amount</p>
                    <h5>{Math.round(user.alowablewithdrawal/1e18)} AW</h5><br></br>
                  </div>
                </div>
                <div className="col-md-4 col-6">
{/*                  <div className="Personal_Details_inner">
                    <img src={require("../assests/icon3.png")} alt="" />
                    <h4>Your sponsor</h4>
                    <h5>{user && user.referrer}</h5>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="pb_30">
            <div>
              <div className="sm_container">
                <div className="sm_container_bg">
                  <div className="sm_container_padding">
                    <div className="all_heading text-center">
                      <h3 id="tt_023">Wallet balance </h3>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="form-group mb_20">
                          <label className="white_label" id="tt_024">
                            Available AW tokens for withdrawal (include 5% Admin Fee)
                          </label>
                          <input
                            className="cus_input"
                            type="text"
                            value={ (withdrawableAmount < user.alowablewithdrawal  /1e18)?
                              Math.round(withdrawableAmount*.95)
                            : Math.round(user.alowablewithdrawal /1e18*.95)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb_2alow0">
                          <label className="white_label" id="tt_025">
                            Reinvestment Amount (AW)
                          </label>
                          <input
                            className="cus_input"
                            type="text"
                            value={Math.round(withdrawableAmount < user.alowablewithdrawal / 1e18)?
                              Math.round(withdrawableAmount * reinvest / 100 * .95)
                             : Math.round(user.alowablewithdrawal /1e18 * reinvest / 100 * .95)}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb_20">
                          <label className="white_label" id="tt_026">
                            Withdrawable Amount (AW)
                          </label>
                          <input
                            className="cus_input"
                            type="text"
                            value={Math.round(withdrawableAmount < user.alowablewithdrawal / 1e18)?
                              Math.round(withdrawableAmount * withdraw / 100 * .95)
                             : Math.round(user.alowablewithdrawal /1e18 * withdraw / 100*.95)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb_20">
                      <p className="white_label mb-0">
                        <a id="tt_027">Reinvestment percentage:</a> <strong>{reinvest}%</strong>
                      </p>
                      <p className="white_label">
                        <a id="tt_028">Withdrawable percentage:</a> <strong>{withdraw}% </strong>
                      </p>
                    </div>
                    <div className="text-center mt_20">
                      <button
                        className="grad_btn btn_300"
                        onClick={withdrawAmount}
                        disabled={loadStatewith}
                        id="tt_029">
                        {loadStatewith && (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        Withdrawal
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="md_container">
              <div className="all_heading text-center">
                <h3 id="tt_030">Single leg family</h3>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th id="tt_031">Level</th>
                      <th>User ID</th>
                      <th id="tt_032">Investment</th>
                      <th id="tt_033">Income</th>
                    </tr>
                  </thead>
                  <tbody>
                    {familyArray.map((family, i) => {
                        var currentIndex = familyArray.findIndex(
                          (item) => item.id === userAddress
                        );
                        if (i == 0) k = currentIndex + 1;
                        k--;
                        return (
                          <tr className="current_user">
                            <td className="aw_td">
                              {family.id == userAddress ? "You" : Math.abs(k)}
                            </td>
                            <td className="aw_td1">{family.id}</td>
                            <td>{Math.round(family.amount / 1e18)} AW</td>
                            <td>{Math.round(family.amount / 1e18/100)} AW</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="md_container">
              <div className="all_heading text-center">
                <h3 id="tt_034">Family list in levels</h3>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th id="tt_035"> Level </th>
                      <th id="tt_036"> Count </th>
                      <th id="tt_037"> Invest Amount </th>
                      <th id="tt_038"> Income </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="lebel_1">
                      <td className="aw_td">Level-1</td>
                      <td>{level1._noOfUser ? level1._noOfUser : 0}</td>
                      <td>
                        {level1._investment ? level1._investment / 1e18 : 0} AW
                      </td>
                      <td>{level1._bonus ? level1._bonus / 1e18 : 0} AW </td>
                    </tr>
                    <tr className="lebel_2">
                      <td className="aw_td">Level-2</td>
                      <td>{level2._noOfUser ? level2._noOfUser : 0}</td>
                      <td>
                        {level2._investment ? level2._investment / 1e18 : 0} AW
                      </td>
                      <td>{level2._bonus ? level2._bonus / 1e18 : 0} AW </td>
                    </tr>
                    <tr className="lebel_3">
                      <td className="aw_td">Level-3</td>
                      <td>{level3._noOfUser ? level3._noOfUser : 0}</td>
                      <td>
                        {level3._investment ? level3._investment / 1e18 : 0} AW
                      </td>
                      <td>
                        {level3._bonus ? level3._bonus / 1e18 : 0} AW <a id="tt_039">(require 4
                        sponsors)</a>
                      </td>
                    </tr>
                    <tr className="lebel_4">
                      <td className="aw_td">Level-4</td>
                      <td>{level4._noOfUser ? level4._noOfUser : 0}</td>
                      <td>
                        {level4._investment ? level4._investment / 1e18 : 0} AW
                      </td>
                      <td>
                        {level4._bonus ? level4._bonus / 1e18 : 0} AW <a id="tt_040">(require 6
                        sponsors)</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="pb_30">
            <div>
              <div className="sm_container">
                <div className="sm_container_bg">
                  <div className="sm_container_padding">
                    <div className="all_heading text-center">
                      <h3 id="tt_041">Your referral link</h3>
                    </div>
                    <h4 className="referal_text word-break">
                      {url + userAddress}
                    </h4>
                    <div className="text-center mt_20">
                      <button
                        className="grad_btn btn_300"
                        onClick={() => {
                          url &&
                            userAddress &&
                            navigator.clipboard.writeText(url + userAddress);
                          toast.success("Copied successfully");
                        }}
                        id="tt_042">
                        Copy Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30">
            <div>
              <div className="md_container">
                <div className="mb_40">
                  <div className="container">
                    <div className="all_heading text-center">
                      <h3>Plan PDF n Addon</h3>
                    </div>
                    <div className="row">
                      <ul className="pdf_downlad_ul">
                        <div className="col-sm-12">
                          <li>
                            <a
                              target="_blank"
                              href={pdf}
                              download="MDF.pptx.pdf"
                            >
                              <span>
                                <i className="fa fa-file-pdf-o"></i>
                              </span>
                              <h5>MDF English <br></br>PDF</h5>
                              <p id="tt_043">
                                Download <i className="fa fa-download"></i>
                              </p>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={pdf}
                              download="MDF.Tutorial.pdf"
                            >
                              <span>
                                <i className="fa fa-file-pdf-o"></i>
                              </span>
                              <h5>MDF Guide English <br></br>PDF</h5>
                              <p id="tt_044">
                                Download <i className="fa fa-download"></i>
                              </p>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={"https://forms.gle/agZpJHzncfKikiwbA"}
                            >
                              <span>
                                <i className="fa fa-file-pdf-o"></i>
                              </span>
                              <h5>MDF NFC NAME CARD APPLICATIONS</h5>
                              <p id="tt_044">
                                APPLY
                              </p>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href={"https://forms.gle/47arDLkfsYurTqN46"}
                            >
                              <span>
                                <i className="fa fa-file-pdf-o"></i>
                              </span>
                              <h5>MDF DEBIT CARD APPLICATIONS</h5>
                              <p id="tt_046">
                                APPLY
                              </p>
                            </a>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer1">
          <div>
            <h1 className="mdf">Mutual Digital Funding</h1>
            <hr />
            <p>© 2022 MutualDigitalFunding All Rights Reserved. </p>
          </div>
        </div>
      </div>
</div>

  );
}
export default Home;
